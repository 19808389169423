<template>
  <ContentWrapper>
    <template v-slot:title>Edit New Contract Specification</template>
    <Container class="contract-wrapper">
      <div class="save-button">
        <base-button  @click="editForm"><font-awesome-icon icon="edit"/> Edit</base-button>
      </div>
      <div class="contract-options">
        <div class="contract-options-form">
          <div class="form-group">
            <label for="exchange">Exchange*</label>
            <input
              type="text"
              id="exchange"
              v-model="contract.exchange"
              placeholder="E.g Chicago Mercantile Exchange (CME)"
            />
          </div>
          <div class="form-group">
            <label for="product">Product*</label>
            <input
              type="text"
              id="product"
              v-model="contract.product"
              placeholder="E.g E-mini S&P 500 Futures"
            />
          </div>
          <div class="form-group">
            <label for="productSymbol">Product Symbol</label>
            <input
              type="text"
              id="productSymbol"
              v-model="contract.product_symbol"
              placeholder="E.g ES"
            />
          </div>
          <div class="form-group">
            <label for="contractUnit">Contract Unit</label>
            <input type="text" id="contractUnit" v-model="contract.contract_unit" />
          </div>
          <div class="form-group">
            <label for="minPrice">Min Price Fluctuation</label>
            <input type="text" id="minPrice" v-model="contract.mini_price_fluctuation" />
          </div>
          <div class="form-group">
            <label for="valuePerTick">Value Per Tick</label>
            <input type="text" id="valuePerTick" v-model="contract.value_per_tick" />
          </div>
          <div class="form-group">
            <label for="contractMonths">Contact Months</label>
            <input type="text" id="contractMonths" v-model="contract.contract_months" />
          </div>
          <div class="form-group">
            <label for="tradingHours">Trading Hours</label>
            <input type="text" id="tradingHours" v-model="contract.trading_hours" />
          </div>
          <div class="form-group">
            <label for="settlementMethod">Settlement Method</label>
            <input
              type="text"
              id="settlementMethod"
              v-model="contract.settlement_method"
            />
          </div>
          <div class="form-group">
            <label for="initialMargin">Initial Margin</label>
            <input type="text" id="initialMargin" v-model="contract.initial_margin" />
          </div>
          <div class="form-group">
            <label for="initialMarginValue">Initial Margin Value*</label>
            <input
              type="text"
              id="initialMarginValue"
              v-model="contract.initial_margin_val"
            />
          </div>
          <div class="form-group">
            <label for="maintenanceMargin">Maintenance Margin</label>
            <input
              type="text"
              id="maintenanceMargin"
              v-model="contract.maintenance_margin"
            />
          </div>
          <div class="form-group">
            <label for="maintenanceMarginValue">Maintenance Margin Value*</label>
            <input
              type="text"
              id="maintenanceMarginValue"
              v-model="contract.maintenance_margin_val"
            />
          </div>
          <div class="form-group">
            <label for="multiplier">Multiplier*</label>
            <input type="text" id="multiplier" v-model="contract.multiplier" />
          </div>
          <div class="form-group">
            <label for="sourceTitle">Source Title</label>
            <input
              type="text"
              id="sourceTitle"
              v-model="contract.source_title"
            />
          </div>
          <div class="form-group">
            <label for="sourceUrl">Source Url</label>
            <input
              type="text"
              id="sourceUrl"
              v-model="contract.source_url"
            />
          </div>
        </div>
      </div>
    </Container>
  </ContentWrapper>
</template>

<script>
import { putContract } from "@/services/adminAPI/contracts"
import { eventBus } from "@/main.js";

export default {

  data() {
    return {
      contract: {

      }
    };
  },
  methods: {
    async editForm(){
      eventBus.$emit("loader", true);
      try {
        await putContract(this.contract.id, this.contract)
        eventBus.$emit("loader", false);
        this.$swal({
          icon: 'success',
          title: 'Contract Edited',
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        }).then(() => {
          this.$router.go(-1)
        })
      } catch (error) {
        console.log(error.response)
        eventBus.$emit("loader", false);
        this.$swal({
          icon: 'warning',
          title: "Contract Create Fail",
          text: error.response.data.userMessage,
          showConfirmButton: true,
        });
      }
    }
  },
  created(){
    this.contract = JSON.parse(JSON.stringify(this.$route.query[0])) || {};
  }
};
</script>

<style lang="scss" scoped>

.save-button{
    float: right;
}

.contract-options {
  display: grid;
  grid-template-columns: 1fr;
  &-form {
    padding: 1rem;
  }
}
@include respond(phone){
  .contract-wrapper{
    height: calc(100vh - 60px - 58px);
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    .contract-options{
      width: 100%;
    }
  }
}
</style>
